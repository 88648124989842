<template>
  <v-card
    :ripple="false"
    width="100%"
    height="100%"
    rounded="lg"
    class="pa-6"
  >
    <p class="text-left text-h6 font-weight-medium">
      <span class="d-flex align-center">
        <v-icon
          color="primary"
          left
          >mdi-information-outline</v-icon
        >
        <span class="primary--text text-uppercase">{{
          $t('title-general-info')
        }}</span>
      </span>
    </p>
    <div>
      <div class="mt-2">
        <ul>
          <li>
            <b>{{ $t('row-status') }}</b>
            <span>: {{ formatQuotationStatus(myQuotation.status) }}</span>
          </li>
          <li>
            <b>{{ $t('row-created-at') }}</b>
            <span>: {{ formatDatetime(myQuotation.createdAt) }}</span>
          </li>
        </ul>
      </div>

      <div class="mt-2">
        <div class="text-h6 text--primary">
          {{ $t('ship-schedules') }}
        </div>
        <div class="mt-2">
          <ul>
            <li>
              <b>{{ $t('row-quotation-code') }}</b>
              <span>: {{ myQuotation.code }}</span>
            </li>
            <li>
              <b>{{ $t('row-vessel') }}</b>
              <span>: {{ myQuotation.vesselName }}</span>
            </li>
            <li>
              <b>{{ $t('row-voyage') }}</b
              ><span>: {{ myQuotation.voyageCode }}</span>
            </li>
            <li>
              <b>{{ $t('row-etd-eta') }}</b
              ><span
                >: {{ $filters.formatDate(myQuotation.etd) }} -
                {{ $filters.formatDate(myQuotation.eta) }}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-2">
        <div class="text-h6 text--primary">
          {{ $t('title-contact-infor') }}
        </div>
        <div class="mt-2">
          <ul>
            <li>
              <b>{{ $t('row-name') }}</b>
              <span>: {{ myQuotation.senderName }}</span>
            </li>
            <li>
              <b>{{ $t('row-address') }}</b>
              <span>: {{ myQuotation.senderAddress }}</span>
            </li>
            <li>
              <b>Email</b><span>: {{ myQuotation.senderEmail }}</span>
            </li>
            <li>
              <b>{{ $t('row-phone') }}</b
              ><span>: {{ myQuotation.senderPhone }}</span>
            </li>
            <li>
              <b>{{ $t('row-note') }}</b
              ><span style="white-space: pre-wrap"
                >: {{ myQuotation.senderNote }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import {
  formatQuotationStatus,
  formatQuotationStatusColor,
} from '@/services/quotation.service';

export default {
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('vsl/quotation', ['myQuotation']),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchMyQuotation({ id: this.$route.params.id });
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('vsl/quotation', ['fetchMyQuotation']),
    formatQuotationStatus,
    formatQuotationStatusColor,
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  display: table;
  width: 100%;
  table-layout: fixed;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  width: 170px;
}

span {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
