<template>
  <v-card
    flat
    class="pa-4"
  >
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.mobile }">
      <v-row no-gutters>
        <p class="text-left text-h6 font-weight-regular">
          <span class="d-flex align-center">
            <v-icon
              color="primary"
              large
              left
              >mdi-hazard-lights</v-icon
            >
            <span
              class="primary--text text-uppercase"
              style="white-space: pre-wrap"
              >{{ $t('title-send-cro') }}</span
            >
          </span>
        </p>

        <v-spacer />
        <div>
          <!-- <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" elevation="0">
                <v-icon left>
                  mdi-printer
                </v-icon>
                In
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="downloadFile('docx')" :ripple="false">
                <v-list-item-title>
                  <v-icon left color="blue darken-3">
                    mdi-file-word-box
                  </v-icon>
                  (.docx)
                  </v-list-item-title>
              </v-list-item>
              <v-list-item  @click="downloadFile('pdf')" :ripple="false">
                <v-list-item-title>
                  <v-icon left color="red darken-3">
                    mdi-file-pdf-box
                  </v-icon>
                  (.pdf)
                  </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <v-btn
            color="info"
            class="ml-2"
            :loading="isSubmiting"
            @click="onSubmitContainerCro"
          >
            <v-icon left> mdi-email-send </v-icon>
            {{ $t('button-send-request') }}
          </v-btn>
        </div>
      </v-row>

      <div>
        <v-card
          class="mt-3"
          flat
          rounded="xl"
        >
          <v-card-text style="font-size: 11px">
            <v-row no-gutters>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 10 : 5"
                class="font-weight-bold uppercase"
              >
                {{ $t('col-container-type') }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 2 : 7"
                class="text-left font-weight-bold uppercase"
              >
                {{ $t('col-action') }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <template v-if="!isLoading">
          <v-scroll-y-transition
            group
            leave-absolute
            tag="div"
          >
            <v-card
              v-for="v in $v.containers.$each.$iter"
              :key="v.$model.id"
              :item="v"
              class="mt-2"
              color="background"
              elevation="0"
              rounded="xl"
              :ref="v.$model.id"
            >
              <v-card-text>
                <v-row
                  no-gutters
                  class="align-center"
                >
                  <v-col :cols="$vuetify.breakpoint.mobile ? 10 : 5">
                    <ContainerPicker
                      no-self-load-data
                      :value="v.containerCode.$model"
                      :label="$t('label-container-picker')"
                      outlined
                      dense
                      required
                      hide-details
                      :error-messages="containerCodeErrors(v)"
                      @blur="v.containerCode.$touch()"
                      @change="onSelectContainerItem($event, v.$model.id)"
                    />
                    <!-- {{ item.containerCode }} -->
                  </v-col>
                  <v-col
                    :cols="$vuetify.breakpoint.mobile ? 2 : 7"
                    class="text-left"
                  >
                    <v-btn
                      class="ml-2"
                      icon
                      color="error"
                      @click="onRemoveContainerItem(v.$model.id)"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-scroll-y-transition>
          <v-btn
            class="my-4"
            text
            :ripple="false"
            rounded
            color="primary"
            @click="onAddContainerItem"
          >
            <v-icon left> mdi-plus </v-icon>
            {{ $t('button-add-container') }}
          </v-btn>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import numeral from 'numeral';
import { v4 as uuidv4 } from 'uuid';
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';
import NoData from '@/components/shared/local/base/NoData.vue';
import ContainerPicker from '@/components/shared/local/vsl/ContainerPicker.vue';

export default {
  components: { NoData, ContainerPicker },
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    isLoading: false,
    isSubmiting: false,
    containers: [
      {
        id: uuidv4(),
        containerCode: null,
      },
    ],
  }),
  validations: {
    containers: {
      required,
      minLength: minLength(1),
      $each: {
        containerCode: {
          required,
        },
      },
    },
  },

  computed: {
    ...mapState('vsl/quotationCro', ['myQuotationCro']),
    containerCodeErrors() {
      return (v) => {
        const errors = [];
        const field = v.containerCode;
        if (!field.$dirty) return errors;
        !field.required &&
          errors.push(this.$t('validation-error-container-required'));
        return errors;
      };
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchContainerList();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('vsl/quotation', ['fetchMyQuotationActionLogList']),
    ...mapActions('vsl/quotationCro', [
      'fetchMyQuotationCro',
      'fetchMyQuotationCroList',
      'createQuotationCro',
    ]),
    ...mapActions('vsl/container', ['fetchContainerList']),
    formatDate(value) {
      return dayjs(value).format('DD.MM.YYYY');
    },
    formatQuotationCroStatus(value) {
      if (value === 'CANCELED') {
        return this.$t('quotation-cro-status-1');
      }
      if (value === 'CONFIRMED') {
        return this.$t('quotation-cro-status-2');
      }
      if (value === 'WAITING_SYSTEM_APPROVE') {
        return this.$t('quotation-cro-status-3');
      }
      if (value === 'WAITING_USER_CONFIRM') {
        return this.$t('quotation-cro-status-4');
      }

      return value;
    },
    downloadFile(type) {
      console.log('[LOG] : type', type);
    },
    scrollToElement(item) {
      setTimeout(() => {
        const el = this.$refs[item.id];
        if (el) {
          el[0].$el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 150);
    },
    onSelectContainerItem(item, id) {
      this.containers.map((cont, index) => {
        if (cont.id === id) {
          this.containers[index].containerCode = item.code;
        }
      });
    },
    onAddContainerItem() {
      const newItem = {
        id: uuidv4(),
        containerCode: null,
      };
      this.containers.push(newItem);
      this.scrollToElement(newItem);
    },
    onRemoveContainerItem(itemId) {
      this.containers = this.containers.filter((cont) => cont.id !== itemId);
    },
    async onSubmitContainerCro() {
      this.$v.containers.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isSubmiting = true;
          await this.createQuotationCro({
            quotationId: this.$route.params.id,
            containers: this.containers,
          });
          await this.fetchMyQuotationActionLogList({
            id: this.$route.params.id,
          });
          await this.fetchMyQuotationCroList({
            quotationId: this.$route.params.id,
          });
          this.$message.success(this.$t('text-cro-success'));
          this.$emit('close-dialog');
        } finally {
          this.isSubmiting = false;
        }
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  display: table;
  width: 100%;
  table-layout: fixed;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  width: 150px;
}

span {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
