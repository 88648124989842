<template>
  <v-card
    style="overflow-y: auto"
    :ripple="false"
    width="100%"
    max-height="550px"
    rounded="lg"
  >
    <v-card-text>
      <v-row
        no-gutters
        align="center"
        class="text-left text-h6 font-weight-regular"
      >
        <span class="d-flex align-center">
          <v-icon
            color="primary"
            left
            >mdi-history</v-icon
          >
          <span class="primary--text">LỊCH SỬ</span>
        </span>
        <v-spacer />
        <v-btn
          color="grey darken-2"
          small
          text
          :ripple="false"
          @click="dialog.list = true"
        >
          {{ $t('button-more') }}
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </v-row>
      <v-timeline
        align-top
        dense
        class="mt-2"
      >
        <v-timeline-item
          color="success"
          small
        >
          <v-row
            no-gutters
            v-if="myQuotationActionLogList[0]"
          >
            <v-col>
              <span
                style="font-size: 14px"
                v-html="formatActionType(myQuotationActionLogList[0])"
                >{{
              }}</span>
              <div
                class="text-caption text--secondary"
                style="font-size: 12px"
              >
                Lúc
                {{ $filters.formatTime(myQuotationActionLogList[0].createdAt) }}
                {{ $filters.formatDate(myQuotationActionLogList[0].createdAt) }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <v-dialog
      v-model="dialog.list"
      scrollable
      max-width="550px"
      transition="dialog-transition"
    >
      <MyQuotationActionLogList
        v-if="dialog.list"
        style="height: 80vh"
        @close-dialog="dialog.list = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MyQuotationActionLogList from './MyQuotationActionLogList.vue';
import { formatActionType } from '@/services/quotation.service';

export default {
  components: { MyQuotationActionLogList },
  computed: {
    ...mapState('vsl/quotation', ['myQuotationActionLogList']),
  },
  data: () => ({
    dialog: {
      list: false,
    },
  }),
  async created() {
    await this.fetchMyQuotationActionLogList({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions('vsl/quotation', ['fetchMyQuotationActionLogList']),
    formatActionType,
  },
};
</script>

<style></style>
