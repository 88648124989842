<template>
  <v-card rounded="lg">
    <v-card-text>
      <v-row
        no-gutters
        class="text-left text-h6 font-weight-regular"
      >
        <span class="d-flex align-center">
          <v-icon
            color="primary"
            left
            >mdi-information-outline</v-icon
          >
          <span class="primary--text text-uppercase">{{
            $t('title-contact-infor')
          }}</span>
        </span>
      </v-row>
      <div class="text-left mt-2">
        <div
          v-if="!isLoading"
          class="mb-2"
        >
          <div>
            <span class="font-weight-bold">{{ contact.name }}</span>
            <span class="mr-2"> | {{ contact.phone }}</span>
          </div>
          <div>
            {{ contact.emails.join(', ') }}
          </div>
          <div>
            {{ contact.address }}
          </div>
        </div>
        <template v-if="isLoading">
          <v-card
            class="mb-2"
            flat
          >
            <v-skeleton-loader
              type="text@4"
              :boilerplate="false"
              class="mb-2"
            />
          </v-card>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  async created() {},
  methods: {},
};
</script>

<style></style>
