<template>
  <v-card rounded>
    <v-card-title>
      <v-row no-gutters>
        <div>{{ $t('title-quotation-confirm') }}</div>
        <v-spacer />
        <div class="">
          <v-btn
            :disabled="isLoading"
            text
            class="mr-2"
            @click="onCloseDialog"
            >{{ $t('button-close') }}</v-btn
          >
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="warning"
            @click="onConfirmQuotation"
            >{{ $t('button-confirm') }}</v-btn
          >
        </div>
      </v-row>
    </v-card-title>

    <v-card-text class="py-6"> {{ $t('text-quotation-confirm') }} </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {},
  props: {},
  data: () => ({
    isLoading: false,
  }),
  computed: {},
  async created() {},
  watch: {},
  methods: {
    ...mapActions('vsl/quotation', [
      'confirmMyQuotation',
      'fetchMyQuotation',
      'fetchMyQuotationActionLogList',
      'fetchMyQuotationCommodityList',
    ]),
    onCloseDialog() {
      this.$emit('close-dialog');
    },

    async onConfirmQuotation() {
      try {
        this.isLoading = true;
        await this.confirmMyQuotation();
        await this.fetchMyQuotationActionLogList({ id: this.$route.params.id });
        await this.fetchMyQuotationCommodityList({ id: this.$route.params.id });
        await this.fetchMyQuotation({ id: this.$route.params.id });
        this.$message.success(this.$t('text-quotation-confirm-success'));
        this.$emit('close-dialog');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
