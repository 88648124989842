<template>
  <v-card flat>
    <v-card-text>
      <v-row no-gutters>
        <p class="text-left text-h6 font-weight-regular">
          <span class="d-flex align-center">
            <v-icon
              color="primary"
              large
              left
              >mdi-hazard-lights</v-icon
            >
            <span class="primary--text text-uppercase">{{
              $t('title-cro-list')
            }}</span>
          </span>
        </p>
        <v-spacer />
        <v-btn
          color="orange darken-4"
          dark
          @click="dialog.myQuotationCroCreate = true"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('button-cro-create') }}
        </v-btn>
      </v-row>

      <div style="min-height: 250px">
        <v-card
          class="mt-3"
          elevation="0"
          rounded="xl"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-card-text style="font-size: 11px">
            <v-row no-gutters>
              <v-col
                cols="4"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-cro-no') }}
              </v-col>
              <v-col
                cols="3"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-status') }}
              </v-col>
              <v-col
                cols="2"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-issue-date') }}
              </v-col>
              <v-col
                cols="2"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-expiry-date') }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          v-for="item in myQuotationCroList"
          :key="item.id"
          :item="item"
          class="mt-2"
          color="background"
          elevation="0"
          rounded="xl"
          style="cursor: pointer"
          @click="onShowDialogConfirmCro(item)"
        >
          <v-card-text>
            <v-row
              no-gutters
              class="align-center"
            >
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                class="text-truncate primary--text font-weight-bold"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-cro-no') }}:
                </b>
                {{ item.no }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 3"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-status') }}:
                </b>
                {{ formatQuotationCroStatus(item.status) }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 2"
                :class="{ 'text-center': !$vuetify.breakpoint.mobile }"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-issue-date') }}:
                </b>
                <span v-if="item.issueDate">
                  {{ $filters.formatDate(item.issueDate) }}
                </span>
                <span
                  v-else
                  class="font-italic"
                >
                  {{ $t('none') }}
                </span>
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 2"
                :class="{ 'text-center': !$vuetify.breakpoint.mobile }"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-expiry-date') }}:
                </b>
                <span v-if="item.expiryDate">
                  {{ $filters.formatDate(item.expiryDate) }}
                </span>
                <span
                  v-else
                  class="font-italic"
                >
                  {{ $t('none') }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <NoData v-if="myQuotationCroList.length === 0" />
      </div>
    </v-card-text>
    <v-dialog
      v-model="dialog.myQuotationCroDetail"
      max-width="850px"
    >
      <MyQuotationCroDetail
        v-if="dialog.myQuotationCroDetail"
        :item="selectedCro"
        @close-dialog="dialog.myQuotationCroDetail = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.myQuotationCroCreate"
      max-width="850px"
    >
      <MyQuotationCroCreate
        v-if="dialog.myQuotationCroCreate"
        @close-dialog="dialog.myQuotationCroCreate = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import numeral from 'numeral';

import { mapActions, mapState } from 'vuex';
import NoData from '@/components/shared/local/base/NoData.vue';
import MyQuotationCroDetail from './MyQuotationCroDetail.vue';
import MyQuotationCroCreate from './MyQuotationCroCreate.vue';
export default {
  components: { NoData, MyQuotationCroDetail, MyQuotationCroCreate },
  data: () => ({
    dialog: {
      myQuotationCroDetail: false,
      myQuotationCroCreate: false,
    },
    selectedCro: null,
  }),
  computed: {
    ...mapState('vsl/quotationCro', ['myQuotationCroList']),
  },
  async created() {
    await this.fetchMyQuotationCroList({ quotationId: this.$route.params.id });
  },
  methods: {
    ...mapActions('vsl/quotationCro', ['fetchMyQuotationCroList']),
    formatQuotationCroStatus(value) {
      if (value === 'CANCELED') {
        return this.$t('quotation-cro-status-1');
      }
      if (value === 'CONFIRMED') {
        return this.$t('quotation-cro-status-2');
      }
      if (value === 'WAITING_SYSTEM_APPROVE') {
        return this.$t('quotation-cro-status-3');
      }
      if (value === 'WAITING_USER_CONFIRM') {
        return this.$t('quotation-cro-status-4');
      }
    },
    onShowDialogConfirmCro(item) {
      this.selectedCro = item;
      this.dialog.myQuotationCroDetail = true;
    },
  },
};
</script>

<style></style>
