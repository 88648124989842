<template>
  <v-card flat>
    <v-card-text>
      <p class="text-left text-h6 font-weight-regular">
        <span class="d-flex align-center">
          <v-icon
            color="primary"
            large
            left
            >mdi-form-select</v-icon
          >
          <span class="primary--text text-uppercase">{{
            $t('title-surcharge')
          }}</span>
        </span>
      </p>

      <div style="min-height: 250px">
        <v-card
          class="mt-3"
          elevation="0"
          rounded="xl"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-card-text style="font-size: 11px">
            <v-row no-gutters>
              <v-col
                cols="2"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-surcharge') }}
              </v-col>
              <v-col
                cols="2"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-type') }}
              </v-col>
              <v-col
                cols="1"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-size') }}
              </v-col>
              <v-col
                cols="2"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-payment') }}
              </v-col>
              <v-col
                cols="1"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-price') }}
              </v-col>
              <v-col
                cols="1"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-currency') }}
              </v-col>
              <v-col
                cols="1"
                class="text-center font-weight-bold text-uppercase"
              >
                {{ $t('col-quantity') }}
              </v-col>
              <v-col
                cols="2"
                class="font-weight-bold text-uppercase"
              >
                {{ $t('col-total') }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-for="item in myQuotationSurchargeList"
          :key="item.id"
          :item="item"
          class="mt-2"
          color="background"
          elevation="0"
          rounded="xl"
        >
          <v-card-text>
            <v-row
              no-gutters
              class="align-center"
            >
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 2"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-surcharge') }}: </b
                >{{ item.surchargeName }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 2"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile"> {{ $t('col-type') }}: </b
                >{{ item.surchargeItemName }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 1"
                :class="{ 'text-center': !$vuetify.breakpoint.mobile }"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile"> {{ $t('col-size') }}: </b>
                {{ item.containerType }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 2"
                :class="{ 'text-center': !$vuetify.breakpoint.mobile }"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-payment') }}:
                </b>
                {{ formatType(item.type) }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 1"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-price') }}:
                </b>
                {{ formatCurrency(item.price) }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 1"
                :class="{ 'text-center': !$vuetify.breakpoint.mobile }"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-currency') }}:
                </b>
                {{ item.currencyCode }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 1"
                :class="{ 'text-center': !$vuetify.breakpoint.mobile }"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-quantity') }}:
                </b>
                {{ item.quantity }}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 2"
                class="text-truncate"
              >
                <b v-if="$vuetify.breakpoint.mobile">
                  {{ $t('col-total') }}:
                </b>
                {{ formatCurrency(item.totalPrice) }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <NoData v-if="myQuotationSurchargeList.length === 0" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import numeral from 'numeral';

import { mapActions, mapState } from 'vuex';
import NoData from '@/components/shared/local/base/NoData.vue';
export default {
  components: { NoData },
  computed: {
    ...mapState('vsl/quotation', ['myQuotationSurchargeList']),
  },
  async created() {
    await this.fetchMyQuotationSurchargeList({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions('vsl/quotation', ['fetchMyQuotationSurchargeList']),
    formatDate(value) {
      return dayjs(value).format('DD.MM.YYYY');
    },
    formatTime(value) {
      return dayjs(value).format('HH:mm');
    },
    formatCurrency(value) {
      return numeral(value).format(0, 0);
    },
    formatType(value) {
      if (value === 'FREE') {
        return this.$t('surchage-type-1');
      }
      if (value === 'PAID') {
        return this.$t('surchage-type-2');
      }
      if (value === 'LIQUIDATION_CONTAINER_OR_LIQUIDATION_GOODS') {
        return this.$t('surchage-type-3');
      }
      if (value === 'LIQUIDATION_CONTAINER') {
        return this.$t('surchage-type-4');
      }
      if (value === 'LIQUIDATION_GOODS') {
        return this.$t('surchage-type-5');
      }
      if (value === 'GUTTED') {
        return this.$t('surchage-type-6');
      }
      return value;
    },
  },
};
</script>

<style></style>
